<template>
  <div class="page">
    <search-field title="高级搜索">
      <el-form :inline="true" size="medium" label-width="100px">
        <el-row>
          <el-form-item label="登录名">
            <el-input clearable v-model="search.searchFields.login_name" placeholder="请输入登录名"></el-input>
          </el-form-item>
          <el-form-item label="角色">
            <el-select clearable size="small" v-model="search.searchFields.role_id" placeholder="请选择角色">
              <el-option
                v-for="item in roleList"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" @click="getUserList(1)" size="small">查询</el-button>
        <el-button @click="clearSearch" size="small" plain>重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="goAddUser">添加员工</el-button>
      </div>
      <v-table
        :data="options.data"
        :columns="options.columns"
        v-on:buttonClick="handleButtonClick"
        :loading="options.loading"
        :isBackPage="true"
        :totalCount="options.totalCount"
        :defaultPageSize="20"
        :defaultcurrentPage="search.searchFields.page"
        @handleCurrentChange="handlePageChange"
        @handleSizeChange="handleSizeChange"
      ></v-table>
    </div>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="password.showPasswordDialog"
      width="400px"
      :close-on-click-modal="false"
      :show-close="false">
      <el-form data-vv-scope="password" @submit.native.prevent>
        <el-form-item
          label="新密码"
          :error="errorBags.collect('password.密码')[0]">
          <el-input clearable
            type="password"
            v-model="password.editFields.pwd"
            name="密码"
            v-validate="'required|alpha_dash|max:16|min:8'"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPassword">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { userApi } from '@/api'
import { BAApi } from '@/api'
import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'

export default {
  data: () => ({
    options: {
      columns: [
        {
          label: '登录名',
          key: 'login_name',
          width: 160
        },
        {
          label: '姓名',
          type: 'html',
          key: 'name'
        },
        {
          label: '角色',
          key: 'role_name'
        },
        {
          label: '手机',
          key: 'mobile'
        },
        {
          label: '状态',
          key: 'status_msg'
        },
        {
          label: '操作',
          type: 'action',
          width: 180,
          multiActions: true,
          buttonInfos: [{
            name: 'edit',
            label: '编辑员工',
          },
          {
            name: 'password',
            label: '修改密码',
            color: 'danger'
          }]
        }
      ],
      data: [],
      totalCount: 0,
      loading: false
    },

    search: {
      searchFields: {
        login_name: '',
        role_id: '',
        status: 1,
        dept_id: '',
        page: 1,
        count: 20
      }
    },

    password: {
      editFields: {
        id: '',
        pwd: ''
      },
      showPasswordDialog: false
    },

    arrangement: {
      arrList: [],
      arrId: '',
      id: '',
      showArrDialog: false
    },

    showLineDialog: false,

    groupList: [],
    statusList: [
      {
        code: 1,
        value: '正常'
      },{
        code: 2,
        value: '禁用'
      }
    ],
    roleList: [
      {
        code: 1,
        value: '管理员'
      },{
        code: 2,
        value: '大区经理'
      },{
        code: 3,
        value: 'BA经理'
      }
    ],
  }),

  methods: {
    // 获取员工列表
    async getUserList(isSearch = 0) {
      this.options.loading = true
      try {
        if (isSearch) {
          this.search.searchFields.page = 1
        }
        let data = (await BAApi.getEmployeeList( this.search.searchFields)).data
        if (data.code === 0) {
          this.options.totalCount = parseInt(data.data.total_count) || 0
          this.options.data = data.data.employee
        } else {
          this.options.data = []
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.options.loading = false
      }
    },
    // 点击事件
    handleButtonClick(data) {
      if (data.button === 'password') {
        this.password.showPasswordDialog = true
        this.password.editFields.employee_id = data.data.id
      }
      if (data.button === 'edit') {
        this.$router.push({
          name: 'employeeEdit',
          query: {
            id: data.data.id
          }
        })
      }
    },
    // 修改密码
    changePassword() {
      this.$validator.validateAll('password').then(status => {
        if (status) {
          BAApi.changeUserPassword(this.password.editFields).then(res => {
            let data = res.data
            if (data.code === 0) {
              this.getUserList()
              this.$message({
                message: '修改密码成功',
                type: 'success'
              })
              this.password.editFields = {
                id: '',
                pwd: ''
              }
              this.password.showPasswordDialog = false
            } else {
              this.$error(data)
            }
          }).catch(err => {
            console.error(err)
          })
        } else {
          this.$message({
            message: '请正确填写密码',
            type: 'error'
          })
        }
      })
    },

    cancelPassword() {
      this.$validator.reset()
      this.password.editFields = {
        id: '',
        pwd: ''
      }
      this.password.showPasswordDialog = false
    },
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getUserList()
    },

    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getUserList()
    },

    clearSearch() {
      this.search.searchFields = Object.assign({}, {
        login_name: '',
        role_id: '',
        status: 1,
        dept_id: '',
        page: 1
      }, { count: this.search.searchFields.count })
    },

    goAddUser() {
      this.$router.push({
        name: 'employeeAdd'
      })
    }
  },

  mounted() {
    this.getUserList()
  },

  components: {
    vTable, searchField
  }

}
</script>
